var _logLoaded = { network: {}, js: {}, res: {} }

/**
 * Filter repeated function
 * Example: `isRepeat(panel, data)`
 * @param {String} key to log type
 * @param {Object} object to log data
 */
export function isRepeat (panel, data) {
  var key
  switch (panel) {
    case 'network':
      if (data && data.resourceUrl) {
        key = `${window.location.href.toString()}|${data.resourceUrl}`
        if (_logLoaded['network'][key]) {
          return true
        } else {
          _logLoaded['network'][key] = 1
        }
      }
      break
    case 'js':
      if (data && data.msg) {
        key = `${window.location.href.toString()}|${data.msg}`
        if (_logLoaded['js'][key]) {
          return true
        } else {
          _logLoaded['js'][key] = 1
        }
      }
      break
    case 'res':
      if (data && data.XPath) {
        key = `${window.location.href.toString()}|${data.XPath}`
        if (_logLoaded['res'][key]) {
          return true
        } else {
          _logLoaded['res'][key] = 1
        }
      }
      break
  }
  return false
}
