import {
  getResolution,
  getPageViewSize,
  getCurrentPageScreen
} from './util/screen';

import { getHash } from './util/url';

import { parseStr } from './util/str';

/**
 * Send loadMsg function
 * Example: `loadMsg(this.instantUploader)`
 * @param {Function} function to upload log data
 */
export function loadMsg (uploader, opts) {
  setTimeout(function () {
    var lData = {
      resolution: getResolution(),
      pageviewsize: getPageViewSize(),
      currentpagescreen: getCurrentPageScreen(),
      loadstatus: 'success'
    };

    Object.assign(lData, opts.param);

    if (typeof window.courseDatanSerach === 'string' || window.courseDatanSerach instanceof String) {
      var strObj = parseStr(window.courseDatanSerach);
      for (const l in strObj) {
        if (typeof strObj[l] !== 'number') {
          lData[l] = strObj[l].toString();
        } else {
          lData[l] = strObj[l]
        }
      }
    } else if (typeof window.courseDatanSerach === 'object') {
      for (const l in window.courseDatanSerach) {
        if (typeof window.courseDatanSerach[l] !== 'number') {
          lData[l] = window.courseDatanSerach[l].toString();
        } else {
          lData[l] = window.courseDatanSerach[l]
        }
      }
    }

    if (opts.hashchange && opts.hashchange.open && opts.hashchange.pvid && (opts.hashchange.pvid === 'hash')) {
      var hash = getHash();
      if (hash === '') {
        hash = 'index';
      }
      if (hash in opts.hashchange.pageuid) {
        lData.pageuid = opts.hashchange.pageuid[hash].toString();
      }
      if (hash in opts.hashchange.loadParam) {
        Object.assign(lData, opts.hashchange.loadParam[hash]);
      }
    }

    uploader(lData, 'a', 'load');
  }, 300);
}

/**
 * Wrapper of send send loadMsg function
 * Example: `this.loadMsg = loadMsgFn(this.instantUploader)`
 * @param {Function} function to upload log data
 * @return {Function} send load message function
 */
export function loadMsgFn (uploader, opts) {
  return function (data) {
    setTimeout(function () {
      var lData = {
        resolution: getResolution(),
        pageviewsize: getPageViewSize(),
        currentpagescreen: getCurrentPageScreen(),
        loadstatus: 'success'
      };

      Object.assign(lData, opts.param);

      if (typeof window.courseDatanSerach === 'string' || window.courseDatanSerach instanceof String) {
        var strObj = parseStr(window.courseDatanSerach);
        for (const l in strObj) {
          if (typeof strObj[l] !== 'number') {
            lData[l] = strObj[l].toString();
          } else {
            lData[l] = strObj[l]
          }
        }
      } else if (typeof window.courseDatanSerach === 'object') {
        for (const l in window.courseDatanSerach) {
          if (typeof window.courseDatanSerach[l] !== 'number') {
            lData[l] = window.courseDatanSerach[l].toString();
          } else {
            lData[l] = window.courseDatanSerach[l]
          }
        }
      }

      if (opts.hashchange && opts.hashchange.open && opts.hashchange.pvid && (opts.hashchange.pvid === 'hash')) {
        var hash = getHash();
        if (hash === '') {
          hash = 'index';
        }
        if (hash in opts.hashchange.pageuid) {
          lData.pageuid = opts.hashchange.pageuid[hash].toString();
        }
        if (hash in opts.hashchange.loadParam) {
          Object.assign(lData, opts.hashchange.loadParam[hash]);
        }
      }

      Object.assign(lData, data);

      uploader(lData, 'a', 'load');
    }, 300);
  };
}
