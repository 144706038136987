import { getCookieValue } from '../util/cookie';
import { clientUserAgent } from '../util/userAgent';
import { getUrlParams } from '../util/url';

/**
 * return formatted log data function
 * Example: `var msg = baseWrapper(type, data, logid, state, opts)`
 * @param {String} logType of data, a for pageLoad msg, b for click msg, c for others
 * @param {Object} object to inner log data
 * @param {String} logid in broswer cookie
 * @param {Object} current logger state object
 * @param {Object} data options
 * @return {Object} formatted log data in object format
 */
export function baseWrapper (logType, obj, logid, state, opts) {
  var domain = window.document.domain;
  var appData = {};
  if (opts.appid && (domain in opts.appid)) {
    appData = opts.appid[domain];
  }
  // 支持自定义协议
  if (opts.appid && window.location.protocol !== 'http:' && window.location.protocol !== 'https:') {
    for (const i in opts.appid) {
      if (opts.appid[i].type === window.location.protocol) { appData = opts.appid[i]; appData.referer = i; }
    }
  }
  var _appid = appData.appid || '';
  var eventTimesTamp = new Date().getTime();

  var baseData = {
    content: contentWrapper(logType, obj, logid, state, opts),
    appid: _appid,
    clits: eventTimesTamp,
    referer: appData.referer || ''
  };

  return baseData;
}

/**
 * return formatted content data function
 * Example: `contentWrapper(logType, obj, logid, state, opts)`
 * @param {String} logType of data, a for pageLoad msg, b for click msg, c for others
 * @param {Object} object to inner log data
 * @param {String} logid in broswer cookie
 * @param {Object} current logger state object
 * @param {Object} data options
 * @return {Object} formatted content data in object format
 */
function contentWrapper (logType, obj, logid, state, opts) {
  var contentData;
  var domain = window.document.domain;
  var appData = {};
  if (opts.appid && (domain in opts.appid)) {
    appData = opts.appid[domain];
  }
    // 支持自定义协议
  if (opts.appid && window.location.protocol !== 'http:' && window.location.protocol !== 'https:') {
    for (const i in opts.appid) {
      if (opts.appid[i].type === window.location.protocol) { appData = opts.appid[i]; appData.referer = i; }
    }
  }
  var _appid = appData.appid || '';

  if (logType === 'a' || logType === 'b') {
    contentData = {
      data: logDataWrapper(obj, state, opts),
      logid: logid || '',
      prelogid: getCookieValue('prelogid') || '',
      xesid: getCookieValue('xesId') || '',
      userid: getCookieValue('stu_id') || '',
      pageid: window.title || window.document.title || '',
      sessid: '',
      referrer: document.referrer || '',
      ua: window.navigator.userAgent || '',
      ip: window.returnCitySN || { cid: '', cip: '', cname: '' },
      clientid: clientUserAgent(),
      clits: new Date().getTime(),
      appid: _appid
    };
  } else {
    contentData = {
      data: logDataWrapper(obj, state, opts),
      xesid: getCookieValue('xesId') || '',
      userid: getCookieValue('stu_id') || '',
      pageid: window.title || window.document.title || '',
      sessid: '',
      referrer: document.referrer || '',
      ua: window.navigator.userAgent || '',
      ip: window.returnCitySN || { cid: '', cip: '', cname: '' },
      clientid: clientUserAgent(),
      clits: new Date().getTime(),
      appid: _appid
    };
  }
  // 为客户端添加兼容处理
  if (getCookieValue('client_sessionid')) {
    contentData.client_sessionid = getCookieValue('client_sessionid');
  }
  var urlParams = getUrlParams();
  if (urlParams['client_sessionid']) {
    contentData.client_sessionid = urlParams['client_sessionid'];
  }

  return contentData;
}

/**
 * return formatted inner data function
 * Example: `logDataWrapper(obj, state, opts)`
 * @param {Object} object to inner log data
 * @param {Object} current logger state object
 * @param {Object} data options
 * @return {Object} formatted inner data in object format
 */
function logDataWrapper (obj, state, opts) {
  obj.currenthref = window.location.href.toString();
  obj.logorder = (state.logorder++).toString();
  obj.rsd =
    (window.returnCitySN ? window.returnCitySN.cip : '') +
    (window.navigator.userAgent || '') +
    new Date().getTime();
  // obj.urlparams = window.location.search + window.location.hash || '';
  if (!obj.eventid && opts.common && opts.common.eventid) {
    obj.eventid = opts.common.eventid;
  }
  // 支持自定义字段
  if (!obj.extracommon && opts.common) {
    obj.extracommon = opts.common;
  }
  return obj;
}
