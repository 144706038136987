/**
 * Add a event handler
 * Example: `addEvent(this, 'keydown', function () {})`
 * @param {Object} object to attach
 * @param {String} name of event
 * @param {Function} function to trigger
 */
export function addEvent (obj, ev, fn) {
  if (obj.attachEvent) {
    // 针对IE浏览器
    obj.attachEvent('on' + ev, fn);
  } else {
    // 针对FF与chrome
    obj.addEventListener(ev, fn, false);
  }
}

/**
 * Remove a event handler
 * Example: `removeEvent(window, unload, handler)`
 * @param {Object} object to attach
 * @param {String} name of event
 * @param {Function} function to trigger
 */
export function removeEvent (obj, ev, fn) {
  if (obj.detachEvent) {
    obj.detachEvent('on' + ev, fn);
  } else {
    // 针对FF与chrome
    obj.removeEventListener(ev, fn, false);
  }
}

/**
 * Event delegate function
 * Example: `on('body', 'click', '*[data-xeslog-params]', function () {})`
 * @param {String} parent object selector
 * @param {String} name of event
 * @param {String} target object selector
 * @param {Function} function to trigger
 */
export function on (elSelector, eventName, selector, fn) {
  var element = document.querySelector(elSelector);

  addEvent(element, eventName, function (event) {
    var possibleTargets = element.querySelectorAll(selector);
    var target = event.target;

    for (var i = 0, l = possibleTargets.length; i < l; i++) {
      var el = target;
      var p = possibleTargets[i];

      while (el && el !== element) {
        if (el === p) {
          return fn.call(p, event);
        }

        el = el.parentNode;
      }
    }
  });
}

/**
 * DomReady function
 * Example: `domReady(handler)`
 * @param {Function} function to callback
 * @param {String} event context
 */
export function domReady (callback, context) {
  // The public function name defaults to window.docReady
  // but you can pass in your own object and own function name and those will be used
  // if you want to put them in a different namespace
  var readyList = [];
  var readyFired = false;
  var readyEventHandlersInstalled = false;

  // call this when the document is ready
  // this function protects itself against being called more than once
  function ready () {
    if (!readyFired) {
      // this must be set to true before we start calling callbacks
      readyFired = true;
      for (var i = 0; i < readyList.length; i++) {
        // if a callback here happens to add new ready handlers,
        // the docReady() function will see that it already fired
        // and will schedule the callback to run right after
        // this event loop finishes so all handlers will still execute
        // in order and no new ones will be added to the readyList
        // while we are processing the list
        readyList[i].fn.call(window, readyList[i].ctx);
      }
      // allow any closures held by these functions to free
      readyList = [];
    }
  }

  function readyStateChange () {
    if (document.readyState === 'complete') {
      ready();
    }
  }

  if (typeof callback !== 'function') {
    throw new TypeError('callback for docReady(fn) must be a function');
  }
  // if ready has already fired, then just schedule the callback
  // to fire asynchronously, but right away
  if (readyFired) {
    setTimeout(function () {
      callback(context);
    }, 1);
    return;
  } else {
    // add the function and context to the list
    readyList.push({
      fn: callback,
      ctx: context
    });
  }
  // if document already ready to go, schedule the ready function to run
  if (document.readyState === 'complete') {
    setTimeout(ready, 1);
  } else if (!readyEventHandlersInstalled) {
    // otherwise if we don't have event handlers installed, install them
    if (document.addEventListener) {
      // first choice is DOMContentLoaded event
      document.addEventListener('DOMContentLoaded', ready, false);
      // backup is window load event
      window.addEventListener('load', ready, false);
    } else {
      // must be IE
      document.attachEvent('onreadystatechange', readyStateChange);
      window.attachEvent('onload', ready);
    }
    readyEventHandlersInstalled = true;
  }
}
