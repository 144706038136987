import { send } from '../util/send';

import { getCookieValue, setCookieValue } from '../util/cookie';

import { md5 } from '../../vendor/md5';

/**
 * Get client ip location function
 * Example: `getIp()`
 * getIp function send jsonp request to get ip location data
 */
export function getIp () {
  send.method.jsonp({
    url: 'https://pv.sohu.com/cityjson?ie=utf-8',
    success: function (data) {
      setCookieValue('prelogid', getCookieValue('X-Request-Id') || md5((window.returnCitySN ? window.returnCitySN.cip : '0.0.0.0') + '_' + (window.navigator.userAgent ? window.navigator.userAgent : 'userAgent') + '_' + new Date().getTime() + '_xes') || '');
    },
    error: function (data) {
      console.log(`后台服务器获取ip出错${data}`);
      setCookieValue('prelogid', getCookieValue('X-Request-Id') || md5((window.returnCitySN ? window.returnCitySN.cip : '0.0.0.0') + '_' + (window.navigator.userAgent ? window.navigator.userAgent : 'userAgent') + '_' + new Date().getTime() + '_xes') || '');
    }
  });
}
