/**
 * parse url query string to K/V object function
 * Example: `var urlParams = getUrlParams()`
 * @return {Object} decoded url query data in Object format
 */
export function getUrlParams () {
  var args = {};
  try {
    var str = window.location.href;
    var num = str.indexOf('?');
    if (num === -1) return args;
    str = str.substr(num + 1).replace(window.location.hash, '');
    var qs = str;
    var items = qs.length ? qs.split('&') : [];
    var item = null;
    var len = items.length;
    for (var i = 0; i < len; i++) {
      item = items[i].split('=');
      var name = item[0];
      var value = item[1];
      if (name) {
        args[name] = value;
      }
    }
  } catch (e) {
    console.log('urlparams parse error');
  }
  return args;
}

export function getHash () {
  return window.location.hash.split('?')[0].split('#/')[1] || '';
}
