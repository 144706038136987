import { addEvent } from './util/event';
import TraceKit from '../vendor/tracekit';

/**
 * Attach normal js runtime exception function
 * Example: `normalException(this.cacheUploader)`
 * @param {Function} function to upload log data
 */
export function normalException (uploader) {
  TraceKit.report.subscribe(function Logger (errorData) {
    if (errorData && errorData.stack && (errorData.stack instanceof Array) && errorData.stack.length) {
      errorData.type = 'Script';
      var el = errorData.stack[0];
      errorData.url = el.url || '';
      errorData.line = el.line || '';
      errorData.col = el.column || '';
      errorData.fileName = el.url || '';
      errorData.msg = errorData.message || '';
      delete errorData['message'];
      if (
        errorData.msg &&
        (typeof errorData.msg === 'string' || errorData.msg instanceof String) &&
        errorData.msg.indexOf('Script error') === -1
      ) {
        uploader(errorData, 'c', 'js');
      }
    }
  });
}

/**
 * Attach unhandledRejection js runtime exception function
 * Example: `unhandledRejection(this.cacheUploader)`
 * @param {Function} function to upload log data
 */
export function unhandledRejection (uploader) {
  function handler (evt) {
    if (evt.reason) {
      var errorData = TraceKit.computeStackTrace(evt.reason);
      errorData.type = 'Script';
      if (errorData && errorData.stack && (errorData.stack instanceof Array) && errorData.stack.length) {
        var el = errorData.stack[0];
        errorData.url = el.url || '';
        errorData.line = el.line || '';
        errorData.col = el.column || '';
        errorData.fileName = el.url || '';
        errorData.msg = errorData.message || '';
        delete errorData['message'];
        if (
          errorData.msg &&
          (typeof errorData.msg === 'string' || errorData.msg instanceof String) &&
          errorData.msg.indexOf('Script error') === -1
        ) {
          uploader(errorData, 'c', 'js');
        }
      }
    }
  }

  addEvent(window, 'unhandledrejection', handler);
}
