/**
 * parse string to K/V object function
 * Example: `var strObj = parseStr(params)`
 * @param {String} string of data in query format
 * @return {Array|Object} decoded data in Object format or empty array
 */
export function parseStr (str) {
  if (str) {
    var changeObject = {};
    var arr = [];
    // 将字符串以&为分割点转换成数组
    arr = str.split('&');
    // 此处过滤掉无效参数，兼容url结尾& 【hasModifiy】
    arr = arr.filter(x => /\w+=\w*/.test(x));
    // 循环字符串转换为了对应键值的对象,字符串必须是ey=main_choose_grade_id&value=3格式
    for (var i = 0; i < arr.length; i++) {
      changeObject[arr[i].split('=')[0].toLowerCase()] = arr[i].split('=')[1];
    }
    return changeObject;
  } else {
    return [];
  }
}
