import { on, addEvent, domReady } from './util/event';
import { getHash } from './util/url';

import {
  getOffsetLT,
  getCurrentPageScreen,
  getPageHeight
} from './util/screen';

import { parseStr } from './util/str';

/**
 * Attach click msg function
 * Example: `clickMsg(this.instantUploader)`
 * @param {Function} function to upload log data
 */
export function clickMsg (uploader, opts) {
  function handler () {
    on('body', 'click', '*[data-xeslog-params]', function (ent) {
      var eleName = this.tagName.toLowerCase().toString();
      var params = this.getAttribute('data-xeslog-params') || '';
      if (
        eleName === 'a' &&
        this.getAttribute('href') &&
        !/^#{1,}$/gi.test(this.getAttribute('href').trim()) &&
        !/^(javascript:).*$/gi.test(this.getAttribute('href').trim())
      ) {
        params += '&targetHref=' + this.getAttribute('href');
      }
      var cData = {
        elemname: eleName,
        posx: getOffsetLT(this).left.toString(),
        posy: getOffsetLT(this).top.toString(),
        currentpagescreen: getCurrentPageScreen(),
        p_hight: getPageHeight(),
        ajaxhref: ''
      };

      Object.assign(cData, opts.param);

      if (opts.hashchange && opts.hashchange.open && opts.hashchange.pvid && (opts.hashchange.pvid === 'hash')) {
        var hash = getHash();
        if (hash === '') {
          hash = 'index';
        }
        if (hash in opts.hashchange.pageuid) {
          cData.pageuid = opts.hashchange.pageuid[hash].toString();
        }
      }

      var strObj = parseStr(params);

      for (var l in strObj) {
        // 不对所有数据输入做统一处理
        if (typeof strObj[l] !== 'number') {
          cData[l] = strObj[l].toString();
        } else {
          cData[l] = strObj[l]
        }
      }

      if (
        this.dataset['xeslog-enter'] &&
        this.dataset['xeslog-enter'] === 'enter'
      ) {
        addEvent(this, 'keydown', function (event) {
          var e = event || window.event;
          if (e && e.keyCode === 13) {
            uploader(cData, 'b', 'click');
          }
        });
      }
      uploader(cData, 'b', 'click');
    });
  }

  domReady(handler);
}

/**
 * Wrapper of send click message function
 * Example: `this.clickMsg = clickMsgFn(this.instantUploader)`
 * @param {Function} function to upload log data
 * @return {Function} send click message function
 */
export function clickMsgFn (uploader, opts) {
  return function (data) {
    if (data.elem === undefined) {
      data.elem = window.document.body;
    }

    var cData = {
      elemname: data.elem.tagName.toLowerCase().toString(),
      posx: getOffsetLT(data.elem).left.toString(),
      posy: getOffsetLT(data.elem).top.toString(),
      currentpagescreen: getCurrentPageScreen(),
      p_hight: getPageHeight(),
      ajaxhref: ''
    };

    if (data.elem) {
      cData.elemname = data.elem.tagName.toLowerCase().toString();
    } else {
      cData.elemname = window.document.body.tagName.toLowerCase().toString();
    }

    Object.assign(cData, opts.param);

    if (opts.hashchange && opts.hashchange.open && opts.hashchange.pvid && (opts.hashchange.pvid === 'hash')) {
      var hash = getHash();
      if (hash === '') {
        hash = 'index';
      }
      if (hash in opts.hashchange.pageuid) {
        cData.pageuid = opts.hashchange.pageuid[hash].toString();
      }
    }

    if (typeof data.params === 'string' || data.params instanceof String) {
      var strObj = parseStr(data.params);
      for (const c in strObj) {
        if (typeof strObj[c] !== 'number') {
          cData[c] = strObj[c].toString();
        } else {
          cData[c] = strObj[c]
        }
      }
    } else if (typeof data.params === 'object') {
      for (const c in data.params) {
        if (typeof data.params[c] !== 'number') {
          cData[c] = data.params[c].toString();
        } else {
          cData[c] = data.params[c]
        }
      }
    }

    uploader(cData, 'b', 'click');
  };
}
