/**
 * Debug log function, if window.__DEVELOPMENT__ === true, write log to specific panel
 * Example: `debugLog(panel, msg);`
 * @param {String} key to debug log type
 * @param {Object} object to log message
 */
export function debugLog (panel, msg) {
  if (window.__DEVELOPMENT__) {
    switch (panel) {
      case 'performance':
        if (window.document.getElementById('performance-msg')) {
          window.document.getElementById(
            'performance-msg'
          ).innerHTML = '';
          window.document.getElementById(
            'performance-msg'
          ).appendChild(window.document.createTextNode(JSON.stringify(msg, null, 3)));
        }
        break;
      case 'network':
        if (window.document.getElementById('network-msg')) {
          window.document.getElementById(
            'network-msg'
          ).innerHTML = '';
          window.document.getElementById(
            'network-msg'
          ).appendChild(window.document.createTextNode(JSON.stringify(msg, null, 3)));
        }
        break;
      case 'load':
        if (window.document.getElementById('load-msg')) {
          window.document.getElementById(
            'load-msg'
          ).innerHTML = '';
          window.document.getElementById(
            'load-msg'
          ).appendChild(window.document.createTextNode(JSON.stringify(msg, null, 3)));
        }
        break;
      case 'js':
        if (window.document.getElementById('runtime-msg')) {
          window.document.getElementById(
            'runtime-msg'
          ).innerHTML = '';
          window.document.getElementById(
            'runtime-msg'
          ).appendChild(window.document.createTextNode(JSON.stringify(msg, null, 3)));
        }
        break;
      case 'click':
        if (window.document.getElementById('click-msg')) {
          window.document.getElementById(
            'click-msg'
          ).innerHTML = '';
          window.document.getElementById(
            'click-msg'
          ).appendChild(window.document.createTextNode(JSON.stringify(msg, null, 3)));
        }
        break;
      case 'res':
        if (window.document.getElementById('resource-msg')) {
          window.document.getElementById(
            'resource-msg'
          ).innerHTML = '';
          window.document.getElementById(
            'resource-msg'
          ).appendChild(window.document.createTextNode(JSON.stringify(msg, null, 3)));
        }
        break;
    }
  }
}
