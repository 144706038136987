/**
 * parse `window.navigator.userAgent` to device client id function
 * Example: `clientUserAgent()`
 * @return {String} decoded useragent data in client id format
 */
export function clientUserAgent () {
  var userAgentInfo = window.navigator.userAgent;
  var clientID = '';
  // 移动端匹配字段
  var Agents = [
    'Android',
    'iPhone',
    'SymbianOS',
    'Windows Phone',
    'iPad',
    'iPod',
    'phone',
    'pad',
    'pod',
    'BlackBerry',
    'Mobile',
    'webOS',
    'ios'
  ];
  var flagSystem = 'web';
  for (var v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) >= 0) {
      // 如果是移动端，需要进一步判断是ios还是Android
      if (/android|Linux/gi.test(userAgentInfo)) {
        // 安卓系统
        flagSystem = 'android';
      } else if (/\(i[^;]+;( U;)? CPU.+Mac OS X/gi.test(userAgentInfo)) {
        // ios系统
        flagSystem = 'ios';
      }
    }
  }
  // 判断是否是PC学生端根据userAgent是否有XesCef字符
  if (/XesCef/gi.test(userAgentInfo)) {
    // PC学生客户端
    flagSystem = 'pc';
  }
  // 根据flagSystem判断是移动端还是web端还是PC学生端,然后给clientID赋值相对应的数值
  switch (flagSystem) {
    // web端即电脑端
    case 'web':
      clientID = '8';
      break;
    case 'android':
      clientID = '9';
      break;
    case 'ios':
      clientID = '10';
      break;
    case 'pc':
      clientID = '7';
      break;
  }
  return clientID;
}

/**
 * isSafari function, if safari browser, return true
 * Example: `var unload = isSafari() ? 'pagehide' : 'unload'`
 * @return {String} true if safari browser and false otherwise
 */
export function isSafari () {
  var ua = navigator.userAgent.toLowerCase();
  if (ua.match(/version\/(\d+).+?safari/)) {
    return true;
  }
  return false;
}
