import { uuid } from '../vendor/uuid'

import { network } from './network'

import { clickMsg, clickMsgFn } from './clickMsg'

import { loadMsg, loadMsgFn } from './loadMsg'

import { exception as resException } from './resource'

import { normalException, unhandledRejection } from './runtime'

import { hashChange } from './hashChange'

import { olPerf } from './performance'

import { instantUploader } from './uploader/instant'

import { cacheUploader } from './uploader/cache'

import { init } from './xes-common/init'

import { vuePluginInstall, vueException } from './plugins/vue'

/**
 * XesLoggerSDK function
 * Example: `new XesLoggerSDK()`
 * @param {Object} logger options
 * XesLoggerSDK is constructor function
 */
var XesLoggerSDK = function (opts, Vue) {
  XesLoggerSDK.fn.setOption(opts)

  XesLoggerSDK.fn.init(Vue)
}

/**
 * XesLoggerSDK prototype object
 */
XesLoggerSDK.fn = XesLoggerSDK.prototype = {
  constructor: XesLoggerSDK
}

/**
 * XesLoggerSDK set options function
 * Example: `XesLoggerSDK.fn.setOption(opts)`
 * @param {Object} logger options
 */
XesLoggerSDK.fn.setOption = function (opts) {
  /*
   * 处理options默认值与外传options
   */
  var self = this
  opts = opts || {}
  this.opts = {
    baseURL: '',
    common: {},
    cacheUploader: {},
    network: {},
    runtime: {},
    performance: {},
    clickMsg: {},
    loadMsg: {},
    resource: {},
    hashchange: {},
    vue: {},
    trace: {},
    appid: {},
    param: {}
  }

  if (opts.common && opts.common.eventid) {
    this.opts.common = opts.common
    // this.opts.common.eventid = opts.common.eventid
  } else {
    throw new Error('请填写{common.eventid}字段')
  }

  if (opts.baseURL) {
    this.opts.baseURL = opts.baseURL.match('/$')
      ? opts.baseURL
      : `${opts.baseURL}/`
  } else {
    // XesLoggerSDK default option `baseURL`
    this.opts.baseURL = 'https://dj.xesimg.com/appid/'
  }

  if (opts.appid) {
    this.opts.appid = opts.appid
  }

  if (opts.param) {
    this.opts.param = opts.param
  }

  var setOptionOrDefault = function (space, key, defaultValue) {
    if (opts[space] && (opts[space][key] !== undefined)) {
      self.opts[space][key] = opts[space][key]
    } else {
      self.opts[space][key] = defaultValue
    }
  }

  setOptionOrDefault('network', 'open', true)

  setOptionOrDefault('network', 'sample', 1)

  setOptionOrDefault('clickMsg', 'open', true)

  setOptionOrDefault('loadMsg', 'open', true)

  setOptionOrDefault('runtime', 'open', true)

  setOptionOrDefault('runtime', 'sample', 1)

  setOptionOrDefault('resource', 'open', true)

  setOptionOrDefault('resource', 'sample', 1)

  setOptionOrDefault('hashchange', 'open', true)

  setOptionOrDefault('hashchange', 'pvid', 'hash')

  setOptionOrDefault('hashchange', 'pageuid', {})

  setOptionOrDefault('hashchange', 'loadParam', {})

  setOptionOrDefault('performance', 'open', true)

  setOptionOrDefault('performance', 'sample', 1)

  setOptionOrDefault('cacheUploader', 'interval', 10000)

  setOptionOrDefault('cacheUploader', 'batch', 5)

  setOptionOrDefault('vue', 'errorHandler', false)

  setOptionOrDefault('trace', 'open', false)
}

/**
 * XesLoggerSDK init function
 * Example: `XesLoggerSDK.fn.init()`
 * Init function set instance variable and install logger modules
 */
XesLoggerSDK.fn.init = function (Vue) {
  // var self = this;
  window.__XES_LOG__ = this;

  var opts = this.opts

  this._state = {
    pvid: uuid() || '',
    ajaxid: 0,
    logorder: 1
  }

  init()

  this.instantUploader = instantUploader(this._state, opts)

  this.cacheUploader = cacheUploader(this._state, opts)

  if (opts.vue.errorHandler) {
    vueException(this.cacheUploader, Vue)
  }

  if (opts.network.open) {
    network(this.cacheUploader, this._state, opts)
  }

  if (opts.clickMsg.open) {
    clickMsg(this.instantUploader, opts)
    this.clickMsg = clickMsgFn(this.instantUploader, opts)
  }

  if (opts.loadMsg.open) {
    loadMsg(this.instantUploader, opts)
  }

  this.loadMsg = loadMsgFn(this.instantUploader, opts)

  if (opts.resource.open && Math.random() < opts.resource.sample) {
    resException(this.cacheUploader, opts)
  }

  if (opts.runtime.open && Math.random() < opts.runtime.sample) {
    normalException(this.cacheUploader)
    unhandledRejection(this.cacheUploader)
  }

  if (opts.hashchange.open) {
    hashChange(this.instantUploader, this._state, opts)
  }

  if (opts.performance.open && Math.random() < opts.performance.sample) {
    olPerf(this.cacheUploader)
  }
}

XesLoggerSDK.install = vuePluginInstall

export default XesLoggerSDK
