import { baseWrapper } from '../xes-common/format';

import { debugLog } from './debug';

import { isRepeat } from './filter';

import { getCookieValue } from '../util/cookie';

import { send } from '../util/send';

/**
 * Instant uploader function
 * Example: `this.instantUploader = instantUploader(this._state, opts)`
 * Instant uploader send log immediately
 * @param {Object} object to logger state
 * @param {Object} object to logger opts
 * @return {Function} send log function
 */
export function instantUploader (state, opts) {
  return function (data, type, panel) {
    var logid = getCookieValue('prelogid') || '';
    var msg = baseWrapper(type, data, logid, state, opts);
    debugLog(panel, msg);

    if (!isRepeat(panel, data) && msg) {
      var eventTimesTamp = new Date().getTime();
      var contents = JSON.stringify(msg.content || {});
      var appid = msg.appid;
      var referer = window.location.host || msg.referer || '';
      var url = `${opts.baseURL}${type}.gif`;
      var body = `content=${contents}`

      send.method.post({
        url: url,
        data: body,
        headers: {
          'X-Log-TimeStamp': eventTimesTamp.toString(),
          'X-Log-Referer': referer,
          'X-Log-Appid': appid
        }
      });
    }
  };
}
