import { addEvent } from './util/event';

import { uuid } from '../vendor/uuid';

import { loadMsg } from './loadMsg';

/**
 * Attach hashChange event function
 * Example: `hashChange(this.instantUploader, this._state, opts)`
 * @param {Function} function to upload log data
 * @param {Object} object to logger state
 * @param {Object} logger options
 * reset logger pvid, ajaxid and logorder state, and send loadMsg log when hashChange event emit
 */
export function hashChange (uploader, state, opts) {
  addEvent(window, 'hashchange', function () {
    if (opts.hashchange && opts.hashchange.pvid && (opts.hashchange.pvid === 'hash')) {
      state.pvid = uuid() || '';
      state.ajaxid = 0;
      state.logorder = 1;
      loadMsg(uploader, opts);
    }
  });

  (function (history) {
    var pushState = history.pushState;
    history.pushState = function (state) {
      if (typeof history.onpushstate === 'function') {
        history.onpushstate({ state: state });
      }

      if (opts.hashchange && opts.hashchange.pvid && (opts.hashchange.pvid === 'hash')) {
        state.pvid = uuid() || '';
        state.ajaxid = 0;
        state.logorder = 1;
        loadMsg(uploader, opts);
      }

      return pushState.apply(history, arguments);
    };
  })(window.history);
}
