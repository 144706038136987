import { getIp } from './ip';

import { getCookieValue, setCookieValue } from '../util/cookie';

import { md5 } from '../../vendor/md5';

/**
 * init log sdk function
 * Example: `init()`
 * Init function get client ip and set prelogid/xesid cookie
 */
export function init () {
  getIp();

  // set xesId
  var xesid;
  if (getCookieValue('xesId')) {
    xesid = getCookieValue('xesId');
  } else {
    var string =
      (window.returnCitySN ? window.returnCitySN.cip : '0.0.0.0') +
      '_' +
      (window.navigator.userAgent ? navigator.userAgent : 'userAgent') +
      '_' +
      new Date().getTime();
    xesid = md5(string);
    setCookieValue('xesId', xesid);
  }
}
