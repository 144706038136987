/**
 * Get screen resolution function
 * Example: `getResolution()`
 * @return {String} resolution of screen
 */
export function getResolution () {
  return (window.screen.width || 0) + '/' + (window.screen.height || 0);
}

/**
 * Get page view size function
 * Example: `getPageViewSize()`
 * @return {String} page view size
 */
export function getPageViewSize () {
  var pageViewHeight = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : document.body.offsetHeight || 0;
  return (
    (window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
        ? document.documentElement.clientWidth
        : document.body.offsetWidth || 0) +
    '/' +
    pageViewHeight
  );
}

/**
 * Get current page position function
 * Example: `getCurrentPageScreen()`
 * @return {String} current screen position of full screen
 */
export function getCurrentPageScreen () {
  var pageViewHeight = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : document.body.offsetHeight || 0;
  return (
    Math.ceil(
      (document.body.scrollTop
        ? document.body.scrollTop
        : document.documentElement.scrollTop) / pageViewHeight
    ) + 1
  ).toString();
}

/**
 * Get offset of left/top position function
 * Example: `getOffsetLT()`
 * @param {Object} object to element
 * @return {Object} object of left and top position
 */
export function getOffsetLT (ele) {
  var top = ele.offsetTop;
  var left = ele.offsetLeft;
  // 将ele换成起相对定位的父元素
  var offsetEle = ele.offsetParent;
  // 只要还有相对定位的父元素
  while (offsetEle) {
    // 获得父元素 距他父元素的top值,累加到结果中
    top += offsetEle.offsetTop;
    left += offsetEle.offsetLeft;
    // 再次将elem换成他相对定位的父元素上;
    offsetEle = offsetEle.offsetParent;
  }
  return {
    left: left,
    top: top
  };
}

/**
 * Get page height function
 * Example: `getPageHeight()`
 * @return {Number} current page height
 */
export function getPageHeight () {
  return Math.max(
    document.body.scrollHeight || 0,
    document.documentElement.scrollHeight || 0,
    document.body.offsetHeight || 0,
    document.documentElement.offsetHeight || 0,
    document.body.clientHeight || 0,
    document.documentElement.clientHeight || 0
  );
}
