import { addEvent, removeEvent } from './util/event';
import { isSafari } from './util/userAgent';

/**
 * Befor onload performance log function
 * Example: `beforOLPerf(this.cacheUploader)`
 * @param {Function} function to upload log data
 * Attach log send function before onload, clear log send function when onload
 */
export function beforOLPerf (uploader) {
  function handler () {
    var pData = {
      type: 'Performance-beforeOL',
      effectiveType: '',
      downlink: 0,
      beforeOLrtt: '',
      platform: '',
      duration: 0
    };
    if (window.navigator && window.navigator.connection) {
      pData.effectiveType =
        window.navigator.connection.effectiveType || pData.effectiveType;
      pData.downlink = window.navigator.connection.downlink || pData.downlink;
      pData.beforeOLrtt = window.navigator.connection.rtt || pData.beforeOLrtt;
      pData.platform = window.navigator.platform || pData.platform;
    }

    var _performance =
      window.performance || window.msPerformance || window.webkitPerformance;

    if (_performance && _performance.now) {
      pData.duration = _performance.now().toFixed(0) * 1 || pData.duration;
    }

    uploader(pData, 'c', 'performance');
  }

  var unload = isSafari() ? 'pagehide' : 'unload';

  addEvent(window, unload, handler);

  function cleanup () {
    removeEvent(window, unload, handler);
  }

  addEvent(window, 'load', cleanup);
}

/**
 * Onload performance log function
 * Example: `olPerf(this.cacheUploader)`
 * @param {Function} function to upload log data
 * Attach log send function, send performance stats from Navigation Timing API
 */
export function olPerf (uploader) {
  function handler () {
    var t;
    var _performance =
      window.performance || window.msPerformance || window.webkitPerformance;

    if (_performance) {
      if (
        _performance.getEntriesByType &&
        _performance.getEntriesByType('navigation') &&
        _performance.getEntriesByType('navigation')[0]
      ) {
        t = _performance.getEntriesByType('navigation')[0];
      } else if (_performance.timing) {
        t = _performance.timing;
      }

      if (t) {
        var pData = {
          type: 'Performance-OL'
        };

        if (t.domainLookupEnd && t.domainLookupStart !== undefined) {
          pData.dns = t.domainLookupEnd - t.domainLookupStart || 0;
        }
        if (t.connectEnd && t.connectStart !== undefined) {
          pData.tcp = t.connectEnd - t.connectStart;
        }

        if (t.responseStart && t.requestStart !== undefined) {
          pData.ttfb = t.responseStart - t.requestStart;
        }

        if (t.responseEnd && t.responseStart) {
          pData.trans = t.responseEnd - t.responseStart;
        }

        if (t.domInteractive && t.responseEnd) {
          pData.dom = t.domInteractive - t.responseEnd;
        }

        if (t.loadEventStart && t.domContentLoadedEventEnd) {
          pData.res = t.loadEventStart - t.domContentLoadedEventEnd;
        }

        var l = window.location;
        if (
          l &&
          l.protocol === 'https:' &&
          t.connectEnd &&
          t.secureConnectionStart
        ) {
          pData.ssl = t.connectEnd - t.secureConnectionStart;
        }

        if (t.responseStart && t.domainLookupStart) {
          pData.firstbyte = t.responseStart - t.domainLookupStart;
        }

        if (t.responseEnd && t.fetchStart) {
          pData.fp = t.responseEnd - t.fetchStart;
        }

        if (t.domInteractive && t.fetchStart) {
          pData.tti = t.domInteractive - t.fetchStart;
        }

        if (t.domContentLoadedEventEnd && t.fetchStart) {
          pData.ready = t.domContentLoadedEventEnd - t.fetchStart;
        }

        if (t.loadEventStart && t.fetchStart) {
          pData.load = t.loadEventStart - t.fetchStart;
        }

        if (window.navigator && window.navigator.connection) {
          pData.effectiveType = window.navigator.connection.effectiveType || '';
          pData.downlink = window.navigator.connection.downlink || '';
          pData.rtt = window.navigator.connection.rtt || '';
        }

        if (window.navigator) {
          pData.platform = window.navigator.platform || '';
        }

        if (_performance && _performance.getEntries) {
          var perfEntries = _performance.getEntries();
          for (var key in perfEntries) {
            if (
              perfEntries[key].name &&
              perfEntries[key].name === 'first-contentful-paint' &&
              perfEntries[key].startTime
            ) {
              pData.fcp = perfEntries[key].startTime.toFixed(0) * 1;
            }
            if (
              perfEntries[key].name &&
              perfEntries[key].name === 'first-paint' &&
              perfEntries[key].startTime
            ) {
              pData.fp = perfEntries[key].startTime.toFixed(0) * 1;
            }
          }
        }

        // 重定向耗时
        if (t.navigationStart !== undefined) {
          pData.rd = t.fetchStart - t.navigationStart
        } else if (t.redirectEnd !== undefined) {
          pData.rd = t.redirectEnd - t.redirectStart
        } else {
          pData.rd = 0
        }

        pData.https = t.nextHopProtocol || '';

        if (_performance.navigation || t.type) {
          if (t.type) {
            switch (t.type) {
              case 'navigate':
                pData.wherepage = 0;
                break;
              case 'back_forward':
                pData.wherepage = 2;
                break;
              case 'reload':
                pData.wherepage = 1;
                break;
              case 'prerender':
                pData.wherepage = 256;
                break;
              default:
                pData.wherepage = 0;
            }
          } else {
            pData.wherepage = _performance.navigation.type || '';
          }
        }

        uploader(pData, 'c', 'performance');
      }
    }
  }

  if (document.readyState && document.readyState === 'complete') {
    handler()
  } else {
    addEvent(window, 'load', handler);
  }
}

/**
 * Get stats of FMP performance data, this function may lead to 20% performance drop
 * Example: `FMP.getFmp(5000)`
 * @param {Number} observe time of fmp
 */
class FMP {
  // via https://github.com/iyjhabc/first-meaningful-paint/blob/master/src/index.js
  /**
   * get first-meaningful-paint
   */
  static getFmp (observeTime = 3000) {
    if (
      !Promise ||
      !window.performance ||
      !window.performance.timing ||
      !window.requestAnimationFrame ||
      !window.MutationObserver
    ) {
      Promise.reject(new Error('fmp can not be retrieved'));
    }

    const promise = new Promise(resolve => {
      const observedPoints = [];
      const observer = new window.MutationObserver(() => {
        const innerHeight = window.innerHeight;

        function getDomMark (dom, level) {
          const length = dom.children ? dom.children.length : 0;
          let sum = 0;
          const tagName = String(dom.tagName).toUpperCase();
          if (
            tagName !== 'SCRIPT' &&
            tagName !== 'STYLE' &&
            tagName !== 'META' &&
            tagName !== 'HEAD'
          ) {
            if (
              dom.getBoundingClientRect &&
              dom.getBoundingClientRect().top < innerHeight
            ) {
              sum += level * length;
            }
            if (length > 0) {
              const children = dom.children;
              for (let i = 0; i < length; i++) {
                sum += getDomMark(children[i], level + 1);
              }
            }
          }
          return sum;
        }
        window.requestAnimationFrame(() => {
          const timing = window.performance.timing;
          const startTime = timing.navigationStart || timing.fetchStart;
          const t = new Date().getTime() - startTime;
          const score = getDomMark(document, 1);
          observedPoints.push({
            score,
            t
          });
        });
      });
      observer.observe(document, {
        childList: true,
        subtree: true
      });

      setTimeout(() => {
        observer.disconnect();
        const rates = [];
        for (let i = 1; i < observedPoints.length; i++) {
          if (observedPoints[i].t !== observedPoints[i - 1].t) {
            rates.push({
              t: observedPoints[i].t,
              rate: observedPoints[i].score - observedPoints[i - 1].score
            });
          }
        }
        rates.sort((a, b) => b.rate - a.rate);
        if (rates.length > 0) {
          resolve(rates[0].t);
        } else {
          resolve(observeTime);
        }
      }, observeTime);
    });
    return promise;
  }
}

/**
 * Wrapper of FMP performance stats function
 * Example: `afterOLPerf(this.cacheUploader)`
 * @param {Function} function to upload log data
 */
export function afterOLPerf (uploader) {
  FMP.getFmp(5000).then(fmp => {
    var pData = {
      fmp: fmp,
      type: 'Performance-afterOL'
    };
    uploader(pData, 'c', 'performance');
  });
}
