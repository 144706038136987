import { baseWrapper } from '../xes-common/format';

import { debugLog } from './debug';

import { isRepeat } from './filter';

import { addEvent } from '../util/event';

import { isSafari } from '../util/userAgent';

import { getCookieValue } from '../util/cookie';

import { send } from '../util/send';

/**
 * Cache uploader function
 * Example: `this.cacheUploader = cacheUploader(this._state, opts)`
 * Cache uploader send a log in three different cases:
 *   - Cache size > opts.cacheUploader.batch
 *   - Every opts.cacheUploader.interval
 *   - Page unload
 * @param {Object} object to logger state
 * @param {Object} object to logger opts
 * @return {Function} add to cache function
 */
export function cacheUploader (state, opts) {
  var _aLogs = [];
  var _bLogs = [];
  var _cLogs = [];

  function batchUpload () {
    var body;
    var contents;
    var domain = window.document.domain;
    var appData = {};
    if (opts.appid && (domain in opts.appid)) {
      appData = opts.appid[domain];
    }
    // 支持自定义协议
    if (opts.appid && window.location.protocol !== 'http:' && window.location.protocol !== 'https:') {
      for (const i in opts.appid) {
        if (opts.appid[i].type === window.location.protocol) { appData = opts.appid[i]; appData.referer = i; }
      }
    }
    var _appid = appData.appid || '';
    var eventTimesTamp = new Date().getTime();

    if (_aLogs.length) {
      body = 'content=';
      contents = _aLogs.splice(0, opts.cacheUploader.batch || 5);
      body += JSON.stringify(contents);
      send.method.post({
        url: `${opts.baseURL}a.gif`,
        data: body,
        headers: {
          'X-Log-TimeStamp': eventTimesTamp.toString(),
          'X-Log-Referer': window.location.host || appData.referer || '',
          'X-Log-Appid': _appid
        }
      });
    }

    if (_bLogs.length) {
      body = 'content=';
      contents = _bLogs.splice(0, opts.cacheUploader.batch || 5);
      body += JSON.stringify(contents);
      send.method.post({
        url: `${opts.baseURL}b.gif`,
        data: body,
        headers: {
          'X-Log-TimeStamp': eventTimesTamp.toString(),
          'X-Log-Referer': window.location.host || appData.referer || '',
          'X-Log-Appid': _appid
        }
      });
    }

    if (_cLogs.length) {
      body = 'content=';
      contents = _cLogs.splice(0, opts.cacheUploader.batch || 5);
      body += JSON.stringify(contents);
      send.method.post({
        url: `${opts.baseURL}c.gif`,
        data: body,
        headers: {
          'X-Log-TimeStamp': eventTimesTamp.toString(),
          'X-Log-Referer': window.location.host || appData.referer || '',
          'X-Log-Appid': _appid
        }
      });
    }
  }

  setInterval(batchUpload, opts.cacheUploader.interval);

  var unload = isSafari() ? 'pagehide' : 'unload';

  addEvent(window, unload, batchUpload);

  return function (data, type, panel) {
    var logid = getCookieValue('prelogid') || '';
    var msg = baseWrapper(type, data, logid, state, opts);
    debugLog(panel, msg);

    if (!isRepeat(panel, data)) {
      switch (type) {
        case 'a':
          _aLogs.push(msg.content);
          if (_aLogs.length >= opts.cacheUploader.batch) {
            batchUpload();
          }
          break;
        case 'b':
          _bLogs.push(msg.content);
          if (_bLogs.length >= opts.cacheUploader.batch) {
            batchUpload();
          }
          break;
        default:
          if (_cLogs.length >= opts.cacheUploader.batch) {
            batchUpload();
          }
          _cLogs.push(msg.content);
      }
    }
  };
}
